/*#mainContent > div > div > div > div > div.MuiGrid-item-61.MuiGrid-grid-md-6-122.Login-welcomeWrap-48 > div > div > div > img {
  width: 300px !important;
}*/

.centrarLogo{
  margin: 0 auto;
  max-width: 250px;
}

.fixedBtn{
  position: fixed !important;
  right: 0;
}