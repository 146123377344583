.loaderContainer {
    position: relative;
    top: -45px;
    height: 285px;
}
.loaderA {
    display: inline-block;
    position: relative;
    width: 57px !important;
    height: 57px !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 999;
}
.loaderA div {
    position: absolute;
    border: 4px solid #F12065; /* cambiar color */
    opacity: 1;
    border-radius: 50%;
    animation: lds 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.loaderA div:nth-child(2) {
    animation-delay: -0.5s;
}
.loaderA span {
    position: absolute;
    width: 200px;
    bottom: -20px;
    left: -120%;
    font-size: 16px;
    opacity: 0.7;
    z-index: 1000;
}
@keyframes lds {
    0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
    }
    100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
    }
}

.loader-progress-wrapper {
    width: 360px;
    height: 100px;
    position: absolute;
    left: calc(50% - 180px);
    top: calc(50% + 140px);
}