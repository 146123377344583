@import "../../styles/mixins";

html {
  color: $base-font-color;
  font-family: $base-font-family;
  font-size: 100%;
  line-height: $base-line-height;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $heading-font-family;
  font-size: modular-scale(1);
  line-height: $heading-line-height;
  margin: 0 0 $small-spacing;
}

p {
  margin: 0 0 $small-spacing;
}

a {
  color: material-color('blue', '500');
  text-decoration-skip-ink: auto;
  transition: color $base-duration $base-timing;

  &:focus {
    outline: none;
    outline-offset: none;
  }
}

hr {
  border-bottom: $base-border;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  margin: $base-spacing 0;
}


[textalign="center"]{text-align: center !important}
[textalign="left"]{text-align: left}
[textalign="right"]{text-align: right}
[textalign="justify"]{text-align: justify}