.enlaces {
  display: inline-block;
  margin-left: 10px;
}

.enlaces a {
  text-decoration: none;
  color: #666;
  font-weight: 600;
  transition: 0.2s;
}

.enlaces a:hover {
  text-decoration: underline;
  transition: 0.2s;
}

.oauthbutton__container {
  margin-bottom: 1rem;
  margin-top: 1rem;
}
