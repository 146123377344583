/**
 * Retrieves a color value from the $material-colors Sass map
 * Inspired by https://github.com/nilskaspersson/Google-Material-UI-Color-Palette/
 *
 * @function
 * @param {string} color-name             - quoted, lower-case, dasherized color
 *                                          name (e.g. 'pink', 'amber')
 * @param {string} [color-variant='500']  - quoted, lowercase color variant (e.g.
 *                                          '200', 'a100')
 */
.TextEditor {
  background: #fff;
  min-height: 200px;
  border: 1px solid #e0e0e0;
  padding: 0 10px; }

.ToolbarEditor {
  background: #fafafa;
  border: none; }

.textPreview {
  width: 100%;
  resize: none;
  height: 305px;
  border-width: 1px;
  border-style: solid;
  border-color: #e0e0e0;
  border-image: initial;
  padding: 5px; }
