.dropzoneTextStyle {
    text-align: center;
    top: 25%;
    position: relative;
}

.dropzoneParagraph {
    font-size: 24px
}

.dropZone {
    position: relative;
    width: 100%;
    height: 250px;
    border: 2px dashed;
    cursor: pointer;
    padding: 20px;
}

.stripes {
    width: 100%;
    height: 250px;
    cursor: pointer;
    border: solid;
    border-color: #C8C8C8;
    background-image: repeating-linear-gradient(-45deg, #F0F0F0, #F0F0F0 25px, #C8C8C8 25px, #C8C8C8 50px);
    -webkit-animation: progress 2s linear infinite !important;
    -moz-animation: progress 2s linear infinite !important;
    animation: progress 2s linear infinite !important;
    background-size: 150% 100%;
}

.rejectStripes {
    width: 100%;
    height: 250px;
    cursor: pointer;
    border: solid;
    border-color: #C8C8C8;
    background-image: repeating-linear-gradient(-45deg, #fc8785, #fc8785 25px, #f4231f 25px, #f4231f 50px);
    -webkit-animation: progress 2s linear infinite !important;
    -moz-animation: progress 2s linear infinite !important;
    animation: progress 2s linear infinite !important;
    background-size: 150% 100%;
}

.fileIconImg {
    color: #909090 !important;
}

.smallPreviewImg {
    height: 100px !important;
    width: initial !important;
    max-width: 100%;
}

@-webkit-keyframes progress {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: -75px 0;
    }
}

@-moz-keyframes progress {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: -75px 0;
    }
}

@-ms-keyframes progress {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: -75px 0;
    }
}

@keyframes progress {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: -70px 0;
    }
}

.imageContainer {
    position: relative;
    z-index: 10;
    margin-bottom: 5px
}

.imageContainer:hover .smallPreviewImg {
    opacity: 0.7;
}

.imageContainer:hover .middle {
    opacity: 1;
}

.imageContainer:hover .middleBigPic {
    opacity: 1;
}

.removeBtn {
    color: white;
    z-index: 3;
}

.middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 0;
    right: 5px;
    z-index: 20
}

.row {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    margin-top: 1rem;
}

.separaChip {
  margin: 0 0.5rem 0.5rem 0;
}
.separaChip:last-child {
  margin-right: 0;
}

.preview {
    margin: 10px
}

.imgWrap,
.fileWrap {
    color: rgba(0, 0, 0, 0.87);
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms !important;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    box-shadow: rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px !important;
    border-radius: 2px;
    z-index: 5;
    overflow: hidden;
    margin-top: 5px;
    margin-right: 8px;
    text-align: center;
    height: 100px;
    position: relative;
}

.imgWrap {
    background: #000;
}

.fileWrap {
    background: #e2e2e2;
    color: #969696;
}

.downloadBtn {
    position: absolute;
    right: 0;
    top: 0;
    text-align: center;
    background: rgba(0, 0, 0, 0.4);
    z-index: 10;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}