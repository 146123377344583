.filters-clasification ul{
    padding-bottom: 11px !important; 
    overflow: hidden !important;
}

.filters-clasification li{
    display: inline-block !important; 
}

.filters-clasification li:first-child {
    display: none !important;
}

.count{
    background-color: #F12065 !important;
    padding: 0px 10px;
    color: white !important;
    font-size: 12px;
    border-radius: 15px;
    max-height: 20.79px;
}

/* Filtro producción y ventas */
.checkSearch-input + label::before{
    min-width: 15px !important;
    height: 15px !important;
    border: 2px solid #ffffff !important;
    background-color: transparent !important;
}

.check-label {
    color: #ffffff !important;
    text-transform: uppercase;
    font-size: 0.875rem;
    font-weight: 400;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    line-height: 1.75;
    margin-right: 16px !important;
}

.check-label > span {
    line-height: inherit !important;
}

.check-label::before {
    background: transparent !important;
    border: 2.5px solid #fff !important;
    flex: 0 0 16px;
}

[class*="GlobalSearch"] li input:focus + .check-label::before {
    box-shadow: none;
}

.check-label::after {
    background-color: #fff !important;
    height: calc(14px - 4px) !important;
    left: 5px !important;
    margin-top: 0 !important;
    top: 7px !important;
    width: calc(14px - 4px) !important;
}

.clasification ul li:nth-child(1) .checkSearch-input:checked + label::before{
    background-color: #FF5A1A !important;
}

.clasification ul li:nth-child(2) .checkSearch-input:checked + label::before{
    background-color: #6eb3d1 !important;
}

.clasification ul li:nth-child(2) .count{
    background-color: #6eb3d1 !important;
}

/* AHDA 25/04/2019 */

.clasification-hidden{
    visibility: hidden
}

/* Filtros Laterales */
.checkFilter-input + label::before{
    min-width: 16px !important;
    border: 1px solid #000000 !important;
    background-color: transparent !important;
}

.checkFilter-label{
    color: rgba(0, 0, 0, 0.87) !important;
}

.checkFilter-input:checked + label::before{
    background-color: #F12065 !important;
    border-color: #F12065 !important;
}

.page >a {
    border-color: transparent !important;
    box-shadow: none !important;
}

.page .active{
    background-color: #F12065 !important;
}

.heigthSearch{
    min-height: 20px !important;
}

.heigthSearch div:first-child > div div:last-child {
    padding: 0 8px 0 20px !important;
}

.m5 {
    margin: 1rem 0 !important;
}
