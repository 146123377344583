.variable-width .slick-slide p {
  background: blue;
  height: 100px;
  color: #fff;
  margin: 5px;
  line-height: 100px;
  text-align: center;
}
.center .slick-center h3 {
  color: #e67e22;
  opacity: 1;
  transform: scale(1.08);
}
.center h3 {
  opacity: 0.8;
  transition: all 300ms ease;
}
.content {
  padding: 20px;
  margin: auto;
  width: 90%;
}
.slick-slide {
  transition: transform .3s
}
.slick-slide .image {
  padding: 10px;
}
.slick-slide img {
  border: 5px solid #fff;
  display: block;
  margin: auto;
}
.slick-slide img.slick-loading {
  border: 0;
}
.slick-slider {
  /* margin: 0 auto 32px; */
  margin: 0 auto 0;
}
.slick-dots {
  margin-left: 0;
}
.slick-thumb {
  bottom: -45px;
}
.slick-thumb li {
  width: 60px;
  height: 45px;
}
.slick-thumb li img {
  filter: grayscale(100%);
}
.slick-thumb li.slick-active img {
  filter: grayscale(0%);
}
@media (max-width: 768px) {
  h3 {
    font-size: 24px;
  }
  .center {
    margin-left: -40px;
    margin-right: -40px;
  }
  .center .slick-center h3 {
    color: #e67e22;
    opacity: 1;
    transform: scale(1);
  }
  .center h3 {
    opacity: 0.8;
    transform: scale(0.95);
    transition: all 300ms ease;
  }
}
.slick-vertical .slick-slide {
  height: 180px;
}
/* button.slick-arrow {
  width: 40px;
  height: 100%;
  padding-top: 5px;
  z-index: 10;
  transition: .2s ease-in;
} */
button.slick-arrow:before{
  font-size: 36px;
}
/* button.slick-arrow:hover,
button.slick-arrow:focus: {
  background-color: grey;
} */
.slick-center{
  transform: scale(1.2);
}
.thumb-nav .slick-dots {
  margin: 20px 0;
  bottom: -60px;
}
.thumb-nav .slick-dots li{
  width: 60px;
  height: auto;
  max-width: none;
}
.thumb-nav .slick-dots li.slick-active{
  border-bottom: 2px solid grey;
}
.custom-arrow .nav-prev,
.custom-arrow .nav-next{
  position: absolute;
  bottom: -40px;
  z-index: 10;
}
.custom-arrow .nav-prev{
  left: 0
}
.custom-arrow .nav-next{
  right: 0
}
/* DEGRADADO BOTONES */
button.slick-arrow {
  background-color: rgba(50,50,50,0.0) !important;
  width: 40px;
  height: 100%;
  height: calc( 100% - 18px );
  padding-top: 5px;
  z-index: 10;
  transition: .2s ease-in;
}
/* button.slick-arrow.slick-prev:hover,
button.slick-arrow.slick-prev:focus {
  background-color: rgba(50,50,50,0.45);
background: -moz-linear-gradient(left, rgba(201,201,201,1) 15%, rgba(255,255,255,0) 100%);
background: -webkit-gradient(left top, right top, color-stop(15%, rgba(201,201,201,1)), color-stop(100%, rgba(255,255,255,0)));
background: -webkit-linear-gradient(left, rgba(201,201,201,1) 15%, rgba(255,255,255,0) 100%);
background: -o-linear-gradient(left, rgba(201,201,201,1) 15%, rgba(255,255,255,0) 100%);
background: -ms-linear-gradient(left, rgba(201,201,201,1) 15%, rgba(255,255,255,0) 100%);
background: linear-gradient(to right, rgba(201,201,201,1) 15%, rgba(255,255,255,0) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c9c9c9', endColorstr='#ffffff', GradientType=1 );
  transition: .2s ease-in;
} */
/* button.slick-arrow.slick-next:hover,
button.slick-arrow.slick-next:focus {
  background-color: rgba(50,50,50,0.45);
background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(201,201,201,1) 87%);
background: -webkit-gradient(left top, right top, color-stop(0%, rgba(255,255,255,0)), color-stop(87%, rgba(201,201,201,1)));
background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(201,201,201,1) 87%);
background: -o-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(201,201,201,1) 87%);
background: -ms-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(201,201,201,1) 87%);
background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(201,201,201,1) 87%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#c9c9c9', GradientType=1 );
  transition: .2s ease-in;
} */
/* MODAL ARROWS */
.modalArrows button.slick-arrow {
  background-color: none !important;
  width: 40px;
  height: 32px;
  padding-top: 0;
  z-index: 10;
  transition: .2s ease-in;
}
.modalArrows button.slick-arrow:hover,
.modalArrows button.slick-arrow:focus {
  background: none;
}
.modalArrows button.slick-arrow.slick-prev:hover,
.modalArrows button.slick-arrow.slick-prev:focus,
.modalArrows button.slick-arrow.slick-next:hover,
.modalArrows button.slick-arrow.slick-next:focus {
  background: none !important;
}
.slick-slide [class*="spacing-xs-24"] {
  margin: 0 !important;
}