*:focus {
    outline: none !important;
}
::-webkit-scrollbar{
    width: 8px;
    height: 8px;
    background-color: #fff;
}
::-webkit-scrollbar-track{
    background-color: #e0e0e0;
}
::-webkit-scrollbar-thumb{
    background-color: #F12065;
}
.marginTopLogo{
    margin-top: 90px;
}
.marginTopTable {
    margin-top: 130px;
}
.marginTopPerfil{
    margin-top: 97px;
}
@media (max-width: 959.95px) {
    .marginTopLogo{
        margin-top: 110px;
    }
}
/* Menu */
.itemText div ~ div{
    padding-left: 0;
}
.itemText div ~ div span{
    font-size: .9rem;
}

/* Estilos de editor de texto */
.ql-editor p{
    font-size: 16px;
}
.ql-align-center{
    text-align: center;
}
.ql-align-right{
    text-align: right;
}
.ql-align-justify{
    text-align: justify;
}