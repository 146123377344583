/**
 * Retrieves a color value from the $material-colors Sass map
 * Inspired by https://github.com/nilskaspersson/Google-Material-UI-Color-Palette/
 *
 * @function
 * @param {string} color-name             - quoted, lower-case, dasherized color
 *                                          name (e.g. 'pink', 'amber')
 * @param {string} [color-variant='500']  - quoted, lowercase color variant (e.g.
 *                                          '200', 'a100')
 */
.bgInfo, div.bgInfo {
  background: #64b5f6; }
  .bgInfo button, div.bgInfo button {
    color: #0d47a1; }

.bgSuccess, div.bgSuccess {
  background: #81c784; }
  .bgSuccess button, div.bgSuccess button {
    color: #1b5e20; }

.bgWarning, div.bgWarning {
  background: #ffb74d; }
  .bgWarning button, div.bgWarning button {
    color: #e65100; }

.bgError, div.bgError {
  background: #e57373; }
  .bgError button, div.bgError button {
    color: #b71c1c; }

.bgDefault, div.bgDefault {
  background: #616161; }

table tr.bgInfo {
  background: #e3f2fd; }

table tr.bgSuccess {
  background: #e8f5e9; }

table tr.bgWarning {
  background: #fff3e0; }

table tr.bgError {
  background: #ffebee; }

table tr.bgDefault {
  background: #FFF; }

.icon {
  width: 30px !important;
  height: 30px !important; }

.messageInfo {
  width: 100%;
  display: flex;
  padding: 10px;
  background: none;
  border: none; }
  .messageInfo .icon {
    background: #64b5f6; }

.messageSuccess {
  width: 100%;
  display: flex;
  padding: 10px;
  background: none;
  border: none; }
  .messageSuccess .icon {
    background: #81c784; }

.messageWarning {
  width: 100%;
  display: flex;
  padding: 10px;
  background: none;
  border: none; }
  .messageWarning .icon {
    background: #ffb74d; }

.messageError {
  width: 100%;
  display: flex;
  padding: 10px;
  background: none;
  border: none; }
  .messageError .icon {
    background: #e57373; }

.messageDefault {
  width: 100%;
  display: flex;
  padding: 10px;
  background: none;
  border: none; }
  .messageDefault .icon {
    background: #e0e0e0; }
