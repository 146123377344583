/**
 * Retrieves a color value from the $material-colors Sass map
 * Inspired by https://github.com/nilskaspersson/Google-Material-UI-Color-Palette/
 *
 * @function
 * @param {string} color-name             - quoted, lower-case, dasherized color
 *                                          name (e.g. 'pink', 'amber')
 * @param {string} [color-variant='500']  - quoted, lowercase color variant (e.g.
 *                                          '200', 'a100')
 */
.bodyForm {
  position: relative;
  background: #FFF;
  padding: 24px;
  max-height: 450px;
  overflow: auto; }
  @media (max-width: 600px) {
    .bodyForm {
      padding: 15px 10px; } }

.buttonArea {
  background: #f5f5f5;
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: right;
  padding: 8px 24px; }
  .buttonArea button {
    margin-right: 5px; }
