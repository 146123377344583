.star {
    display: inline;
}

.star button {
    width: 30px;
    height: 30px;
}

@media only screen and (max-width: 360px) {
    .star button {
        width: 20px;
        height: 20px;
    }
}

.detailObra i,
.detailExtra i,
.detailEvaluacion i,
.detailMasInfo i {
    display: inline-block;
    margin-right: 10px;
    min-width: 70px;
}

.detailEvaluacion iframe {
    display: block;
    margin: auto;
    margin-bottom: 20px !important;
    width: 100% !important;
}

.dBlock {
    display: block !important;
}

.inBlock {
    display: inline-block !important;
}

span.dBlock span,
.pb2>span {
    display: inline-block;
}

/* .espaciadoP p{
    padding: 3px 0;
} */

.PanelDetalle p,
.espaciadoP p {
    margin-bottom: 10px;
}

.PanelDetalle p:last-child {
    margin-bottom: 0;
}

.textJustify {
    text-align: justify;
}

.textCenter {
    text-align: center;
}

.pb2 {
    padding-bottom: .5em;
}

.pb3 {
    padding-bottom: 1em;
}

.pt3 {
    padding-top: 1em;
}

.expansionIcon {
    font-size: 18px !important;
    margin-bottom: -4px !important;
}

.listado {
    list-style-type: disc;
    margin-left: 1em;
    font-size: 14px;
}

.listado a {
    color: #424242;
}

.listado a>span {
    color: #6eb3d1;
}

.listado a:hover {
    border-bottom: 1px solid #6eb3d1;
}