.text-right {
    text-align: right;
}

.itemTotal {
    display: flex;
    flex-flow: row wrap;
}

label[for*="Seleccionar Todo"] > span > span:nth-of-type(2) {
    display: none;
}